/* ----------------------------------------------
:: Template Name: notjamieoliver Blog - Food Blog Template
:: Template Author: Colorlib
:: Template Author URI: https://colorlib.com
:: Version: v1.0.0
:: Last Updated: 11-13-2017
---------------------------------------------- */

/* -------- [Master Stylesheet] --------
:: 1.0 Base CSS
:: 2.0 Top Header Area CSS
:: 3.0 Menu Area CSS
:: 4.0 Wellcome Area CSS 
:: 5.0 Top Feature Area CSS 
:: 6.0 Blog Area CSS 
:: 7.0 Instagram Area CSS 
:: 8.0 Footer Social Area CSS 
:: 9.0 Goole Map Area CSS
:: 10.0 Sidebar Area CSS
:: 11.0 Breadcumb Area CSS
:: 12.0 Paginatio Area CSS
:: 13.0 Contact Area CSS
:: 14.0 Single Blog CSS
----------------------------------- */

/* ***** Fonts ***** */
@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratAlternates';
    src: url('../../fonts/MontserratAlternates-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-Bold.ttf') format('truetype'); 
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-BoldItalic.ttf') format('truetype'); 
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-Italic.ttf') format('truetype'); 
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-Light.ttf') format('truetype'); 
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-LightItalic.ttf') format('truetype'); 
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-Medium.ttf') format('truetype'); 
    
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-MediumItalic.ttf') format('truetype'); 
    
    font-style: italic;
}
@font-face {
    font-family: 'Ubuntu'; 
    src: url('../../fonts/Ubuntu-Regular.ttf') format('truetype'); 
    font-weight: normal;
    font-style: normal;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: #000;
    color: #fff;
    padding: 25px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }

  .button {
    margin-left: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
}

.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}
.cookie-popup button {
    margin-left: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
}

  .cookie-popup a {
    z-index: 1001; /* Sicherstellen, dass der Link anklickbar bleibt */
  }
  
  .cookie-content {
    
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cookie-content p {
    margin: 0;
  }
  
  .cookie-content a {
    color: #fff;
    text-decoration: underline;
  }
  
  .cookie-content button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }
  


/* Background image behaviour */
.bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    height: 600px;
    width: 100vw;
    overflow: hidden;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section_size {
    height: 3vh;
}

.content-box p{
    color:white;
    
    font-size: 20px;
}

.form-check-button {
    margin-right: 16vw;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 10px; /* Abstand zwischen der Checkbox und dem Tag-Label */
}

.form-check-label {
  flex-grow: 1; /* Lässt das Label den freien Platz nutzen */
  margin-right: 10px; /* Abstand zwischen dem Tag-Label und dem Button */
}

/* Ändere die Standardfarbe der Dots */
.owl-carousel .owl-dot span {
    background-color: #000000 !important; /* Die gewünschte Farbe für die Dots */
    width: 12px; /* Größe der Dots */
    height: 12px;
    border-radius: 50%; /* Rund machen */
    display: inline-block;
    margin: 5px;
    opacity: 0.5; /* Halb transparent */
}

/* Wenn ein Dot aktiv ist (aktuelle Slide) */
.owl-carousel .owl-dot.active span {
    background-color: #0d6efd !important; /* Aktive Dot-Farbe */
    opacity: 1; /* Volle Sichtbarkeit für den aktiven Dot */
    transform: scale(1.2); /* Vergrößere den aktiven Dot leicht */
    transition: transform 0.3s ease; /* Weicher Übergang */
}

/* Progress bar container */
.progress-bar-container {
    width: 96%;
    height: 6px; /* Adjust height for the progress bar */
    background-color: rgba(255, 255, 255, 0.3); /* Light background for progress track */
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    left: 20px;
}

/* Progress bar fill */
.progress-bar-fill {
    height: 100%;
    width: 0;
    background-color: #0d6efd; /* Color of the progress bar */
    position: absolute;
    top: 0;
    left: 0;
    animation: fillBar 5s linear infinite; /* 5 seconds = slide duration */
    border-radius: 5px;
}

/* Keyframes for the progress bar animation */
@keyframes fillBar {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

/* Container for owl-carousel */
.owl-carousel-container {
    width: 100%; /* Full width of its parent (Bootstrap column) */
    height: auto;
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center;
    position: relative;
}

/* Navigation Buttons Positionierung */
.carousel-container {
    position: relative;
  }
  
  .button-prev, .button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgb(0, 0, 0); /* Hintergrundfarbe */
    color: white; /* Farbe der Pfeile */
    border-radius: 50%; /* Runde Pfeile */
    cursor: pointer;
    z-index: 10;
    border: 2px solid #0d6efd; /* Blauer Rand */
  }
  
  .button-prev {
    left: -50px; /* Platziert den Button links außerhalb des Carousels */
  }
  
  .button-next {
    right: -50px; /* Platziert den Button rechts außerhalb des Carousels */
  }

/* Positionierung des linken Pfeils */
.owl-carousel .owl-nav .owl-prev {
    left: 0px; /* Links neben dem linken Rand des Karussells */
}

/* Positionierung des rechten Pfeils */
.owl-carousel .owl-nav .owl-next {
    right: 0px; /* Rechts neben dem rechten Rand des Karussells */
}

/* Optional: Hover-Effekt für die Pfeile */
.owl-carousel .owl-nav .owl-prev:hover,
.owl-carousel .owl-nav .owl-next:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Etwas dunkler bei Hover */
    transition: background-color 0.3s ease;
}


/* Individual slide item */
.title_slideshow_item {
    background-color: rgba(0, 0, 0, 0.3); /* Black background with transparency */
    padding: 40px;
    border-radius: 5px;
    width: 100%;
    text-align: left;

}

/* Slideshow text styling */
.slide-title {
    font-size: calc(16px + 1.8vw);
    color: white;
    margin-bottom: 10px;
}

.slide-subtitle {
    font-size: calc(16px + 1vw);
    color: #ffffff;
    
    margin-bottom: 20px;
}

.slide-description {
    font-size: calc(14px + 0.5vw);
    color: white;
    margin-bottom: 30px;
}

/* Button styling */
.btn-outline-primary {
    background-color: #00000052;
    color: white;
    
}

.btn-primary:hover {
    background-color: #0d6efd;
}

.about-container img,
.about-container img a {
    border-radius: 5px;
    border-style: solid;
    border-color: #b5aec4;
    border-width: 2px;
}

/* Ensure the container is centered */
.container.title {
    display: flex;
    justify-content: flex-start; /* Align container content to the left */
    align-items: center;
    height: 100%;
}

.steps-container {
    display: flex;
    flex-direction: column;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: left; /* Stellt sicher, dass sowohl die Zahl als auch der Text vertikal zentriert sind */
    margin-bottom: 10px;
  }
  
  .step-number {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border: 2px solid #0d6efd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; 
    
    margin-right: 15px;
    color: white;
  }
  
  .step-description {
    display: inline-block;
    vertical-align: middle; /* Vertikale Ausrichtung des Textes */
  }

  .tip-icon {
    cursor: pointer;
    width: 40px;
    min-width: 40px;
    
  }
  
  .tip-content {
    width: 100%;
    background-color: var(--bs-primary-bg-subtle) !important;
    border-color: var(--bs-primary-border-subtle) !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    padding: 10px;
    margin-top: 10px;
    margin-left: 70px;
    border-radius: 5px;
    position: relative;
    display: flex;
    --bs-text-opacity: 1;
    color: rgba(13, 110, 253, 1) !important;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 12px; /* Kleiner Button */
    color: red; /* Rote Farbe */
    cursor: pointer;
  }
  
  .close-button:hover {
    color: darkred; /* Farbänderung bei Hover */
  }
  
  .tip-text {
    margin: 0;
    padding: 0;
  }

  .tip-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 10px;
    position: relative;
  }
  
  .tip-container .fa-times {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .breadcumb-title {
    width: 100%;
    overflow: hidden; /* Hilft, überflüssigen Inhalt zu verstecken */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;*/
  }
  
  .img-container img {
    max-width: 100%;
    height: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
    margin-top: 2em;
  }



  .recipe-slide {
    display: flex;
    justify-content: center;
    height: 100%; /* Höhe für die Slides */
    align-items: stretch; /* Streckt den Inhalt, um die gesamte Höhe zu nutzen */
}

.recipe-container {
    display: flex;
    flex-direction: column; /* Stellt sicher, dass das Bild oben und die Infos unten angezeigt werden */
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    border: 0px solid #ddd;
    background-color: none;
    height: 100%; /* Verwendet die volle Höhe */
}

/* Bildbereich oben */
.recipe-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border-style: solid;
    border-color: #b5aec4;
    border-width: 2px;
}
.recipe-image img:hover {
    text-align: center;
    width: 96%;
    border-radius: 5px;
    border-style: solid;
    border-color: #0d6efd;
    border-width: 2px;
    margin: auto;
    display: block;
}

.fa-icon {
    color:green;
}

/* Informationsteil */
.recipe-info {
    margin-top: 15px;
    flex-grow: 1; /* Die Informationen wachsen, um den verbleibenden Platz zu füllen */
}

/* Rezepttitel und Datum */
.recipe-date {
    color: #b5aec4;
    font-size: 14px;
    
}

.recipe-title {
    color: #ffffff;
    font-size: 18px;
    
}

.recipe-info a:hover {
    color: #0d6efd;
}

.recipe-description {
    font-size: 16px;
    color: #555;
}

/* Sicherstellen, dass alle Carousel-Slides dieselbe Höhe haben */
.owl-carousel .owl-item {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
}
    
    .owl-carousel .owl-stage-outer {
        display: flex;
        align-items: stretch;
    }
    
    .owl-carousel .owl-stage {
        display: flex;
        height: 100%;
        align-items: stretch;
    }

.detail-page .subtitle {
    background: rgb(255, 255, 255);
    height: 40px;
    width: 100%;
    line-height: 40px;
    border-radius: 2px;
    margin-bottom: 30px;
    border-radius: 5px;
    color:#0d6efd;
    font-weight: 500;
    font-size: 20px;
}

.detail-page .group-name h5 {
    font-weight: 500;
    border-bottom: solid;
    padding-bottom: 10px;
    border-width: thin;
    border-color: #0d6efd;
}

.post-comment-share-area {
    text-align: end;
}

.post-view span
{
    margin-left: 3px;
}
.post-view p,
.post-view {
    color: #b5aec4;
    font-size: 14px;
    
}

  
/* Newsletter Widget CSS */

.notjamieoliver-widget p {
    font-size: 14px;
}

.notjamieoliver-form {
    position: relative;
    z-index: 1;
}

.notjamieoliver-form2 input {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 2px solid #0d6efd;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 14px;
    color: #0d6efd;
}




/* ***** Import CSS ***** */

/* --------------------
:: 1.0 Base CSS
-------------------- */

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'MontserratAlternates', serif;
    font-weight: 400;
    color: white;
    position: relative;
    z-index: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 200;
    color: #ffffff;
    /* color: #232d37; */ 
    line-height: 1.25;
}

p {
    color: #b5aec4;
    
    font-size: 20px;
    margin-bottom: 0px;
}

.section_padding_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
}

.section_padding_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section_padding_200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section_padding_80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.section_padding_80_50 {
    padding-top: 80px;
    padding-bottom: 50px;
}

.section_padding_30_80 {
    padding-top: 30px;
    padding-bottom: 80px;
}

.section_padding_80_0 {
    padding-top: 80px;
    padding-bottom: 0;
}

.section_padding_0_80 {
    padding-top: 0;
    padding-bottom: 80px;
}

img {
    max-width: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

#scrollUp {
    bottom: -50px;
    font-size: 18px;
    right: 50%;
    width: 40px;
    background-color: #0d6efd;
    color: #fff;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin-top: 50px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    
    outline: none;
}

li {
    list-style: none;
}

.heading_text > p {
    font-size: 16px;
}

.heading_text > h2 {
    
    color: #333;
}

.heading_text > h3 {
    color: #b4b4b4;
    font-size: 90px;
    
    margin: 0;
}

.font-shadow-into-light {
    font-family: 'Shadows Into Light Two', cursive !important;
}

.notjamieoliver-table {
    display: table;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
}

.notjamieoliver-table-cell {
    display: table-cell;
    vertical-align: middle;
}

#preloader {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.notjamieoliver-load {
    -webkit-animation: 2000ms linear 0s normal none infinite running notjamieoliver-load;
    animation: 2000ms linear 0s normal none infinite running notjamieoliver-load;
    background: transparent none repeat scroll 0 0;
    border-color: #dddddd #dddddd #fc6c3f;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    height: 40px;
    left: calc(50% - 20px);
    position: relative;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 9;
}

@-webkit-keyframes notjamieoliver-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes notjamieoliver-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



/* --------------------
:: 2.0 Top Header Area CSS
-------------------- */
.header_area {
    width: 100%;
    color: white; 
    padding: 0px;
}
.top_header_area {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ebebeb;
}

.top_header_area .top_social_bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


.top_header_area .top_social_bar > a {
    font-size: 12px;
    color: #232d37;
    margin-right: 30px;
}

.top_header_area .top_social_bar > a:hover {
    color: #fc6c3f;
}

.top_header_area .top_social_bar > a:last-of-type {
    margin-right: 0;
}

.top_header_area .signup-search-area {
    height: 40px;
    position: relative;
    z-index: 3;
}

.top_header_area .signup-search-area a {
    font-size: 13px;
    color: #222;
}

.top_header_area .signup-search-area .login a {
    margin-right: 13px;
    border-right: 2px solid #c2c2c2;
    padding-right: 13px;
}

.top_header_area .signup-search-area .search_button {
    margin-left: 54px;
}

#searchBtn {
    position: relative;
    z-index: 8;
}

.search-hidden-form {
    position: absolute;
    width: 100%;
    z-index: 4;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    font-size: 12px;
}

.search-hidden-form.search-form-open {
    z-index: 18;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.search-hidden-form #search-anything {
    width: 100%;
    height: 30px;
    border: 1px solid #ddd;
    top: 5px;
    position: relative;
    padding: 5px 10px;
}

.search-hidden-form span {
    line-height: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    display: block;
    text-align: center;
    top: 5px;
    right: 0;
    border: 1px solid #ddd;
    color: #888;
    cursor: pointer;
    z-index: 5;
    font-size: 13px;
    border-left: none;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.search-hidden-form span:hover {
    color: #000;
}

/* --------------------
:: 3.0 Menu Area CSS
-------------------- */
.nav-link {
    color: rgba(255, 255, 255, 1) !important;
    font-weight: 200;
}

.nav-link-active {
    color: #0d6efd !important;
    font-weight: 200;
}


#navbar-logo {
    content: url('../../../../public/assets/img/logo-img/favicon_32x32.png');
    height: 48px;
    width: 48px;
}

@media (min-width: 768px) {
    #navbar-logo {
        content: url('../../../../public/assets/img/logo-img/logo-hohe-80px-toffol-cooking-blau.png');
        width: 150px;
        
  }
}

.logo_area .notjamieoliver-logo {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 80px;
    margin-bottom: 0;
    padding: 35px 0 20px 0;
    display: inline-block;
    color: #232d37;
}

.navbar.navbar-expand-lg {
    padding: 0;
}

.navbar.navbar-expand-lg .navbar-nav .nav-link {
    padding: 25px 30px;
    color: #232d37;
    text-transform: uppercase;
    font-size: 14px;
}

.navbar.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar.navbar-expand-lg .navbar-nav .active .nav-link {
    color: #fc6c3f;
}

.dropdown-item {
    
    color: #232d37;
    font-size: 14px;
    text-transform: uppercase;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #fc6c3f;
    text-decoration: none;
    background-color: #f8f9fa;
}

/* --------------------
:: 4.0 Wellcome Area CSS 
-------------------- */


.welcome-single-slide {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.welcome-single-slide .project_title a > h5 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0;
}

.welcome-single-slide .project_title {
    position: absolute;
    content: "";
    bottom: -300px;
    left: 0;
    z-index: 3;
    background: #0d6dfda5;
    padding: 30px;
    width: 100%;
    height: auto;
    -webkit-transition-duration: 750ms;
    transition-duration: 750ms;
}

.welcome-single-slide:hover .project_title {
    bottom: 0;
}

.welcome-single-slide .post-date-comments a {
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.welcome-single-slide .post-recipe-name a {
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.welcome-single-slide .post-date-comments a:hover,
.welcome-single-slide .post-recipe-name a:hover {
    color: rgba(255, 255, 255, 0.9);
}


.welcome-single-slide .post-date-comments a:first-child {
    margin-right: 30px;
}

.welcome-single-slide .post-date-comments a:first-child:after {
    width: 2px;
    height: 80%;
    content: '';
    position: absolute;
    top: 10%;
    right: -14px;
    z-index: 2;
    background-color: #fff;
}


.welcome-post-sliders {
    position: relative;
    z-index: 1;
}

.welcome-post-sliders .owl-prev {
    position: absolute;
    width: 10%;
    background-color: transparent;
    height: 100%;
    top: 0;
    left: 0;
    cursor: url(../../../../public/assets/img/core-img/back.png), auto !important;
    z-index: 9;
}

.welcome-post-sliders .owl-next {
    position: absolute;
    width: 10%;
    background-color: transparent;
    height: 100%;
    top: 0;
    right: 0;
    cursor: url(../../../../public/assets/img/core-img/next.png), auto !important;
}

/* --------------------
:: 5.0 Top Feature Area CSS 
-------------------- */

.categories_area {
    padding: 80px 0 50px 0;
}

.single_catagory {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.single_catagory img {
    border-radius: 5px;
}

.catagory-title {
    width: 200px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    text-align: center;
    border-radius: 5px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single_catagory:hover .catagory-title {
    background-color: #fc6c3f;
}

.catagory-title a > h5 {
    margin-bottom: 0;
    line-height: 50px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single_catagory:hover .catagory-title a > h5 {
    color: #fff;
}

/* --------------------
:: 6.0 Blog Area CSS 
-------------------- */

.post-thumb img,
.post-thumb img a {
    border-radius: 5px;
    border-style: solid;
    border-color: #b5aec4;
    border-width: 2px;
}

.post-thumb img:hover {
    text-align: center;
    width: 96%;
    border-radius: 5px;
    border-style: solid;
    border-color: #0d6efd;
    border-width: 2px;
    margin: auto;
    display: block;
}

.post-meta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.post-author a,
.post-author,
.post-date,
.post-date a,
.post-favourite a,
.post-comments a,
.post-share a {
    color: #b5aec4;
    font-size: 14px;
    
}

.post-author a:hover,
.post-date a:hover,
.post-favourite a:hover,
.post-comments a:hover,
.post-share a:hover {
    color: #fff;
}

.post-favourite a,
.post-comments a {
    margin-right: 15px;
}

.post-author a {
    margin-right: 30px;
    position: relative;
    z-index: 1;
}

.post-author a:after {
    width: 2px;
    height: 60%;
    content: '';
    position: absolute;
    top: 20%;
    right: -14px;
    z-index: 2;
    background-color: #b5aec4;
}

.single-post {
    margin-bottom: 10px;
}

.single-post span {
    font-weight: 500;
    font-size: 1em;
    margin: 3px;
}

.single-post span a {
    font-weight: 500;
    font-size: 1em;
    margin: 3px;
}

.single-post h2,
.single-post h4 {
    margin: 10px 0;
    color: #ffffff;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    
}

.single-post h2:hover,
.single-post h4:hover {
    color: #0d6efd;
}


.single-post .read-more {
    font-size: 12px;
    color: #b5aeba;
    display: inline-block;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    height: 40px;
    min-width: 170px;
    line-height: 40px;
    text-align: center;
}


.single-post .read-more:hover {
    color: #000;
    border: 1px solid #000;
}

/* List Blog Area CSS */

.list-blog.single-post .post-thumb {
    padding-right: 15px !important;
}

.list-blog.single-post .post-content {
    margin-right: 0;
    margin-bottom: 30px;
}

.list-blog.single-post {
    padding: 15px;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;
}

.blog_area .col-12:last-of-type .list-blog.single-post {
    margin-bottom: 0;
    border-bottom: none;
}

.blog_area .col-12:last-of-type .list-blog.single-post .post-thumb img {
    margin-bottom: 0;
}

/* --------------------
:: 7.0 Instagram Area CSS 
-------------------- */

.instagram_gallery_item {
    position: relative;
    z-index: 1;
}

.instagram_gallery_item img {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 100%;
}

.instagram_gallery_item .hover_overlay {
    background: rgba(252, 108, 63, 0.7);
    height: 100%;
    left: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 100%;
    z-index: 5;
}

.instagram_gallery_item:hover .hover_overlay {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.instagram_gallery_item .follow-me a {
    font-size: 16px;
    color: #fff;
    
}

.instargram_area {
    position: relative;
    z-index: 1;
}

.instargram_area .owl-prev {
    position: absolute;
    width: 10%;
    background-color: transparent;
    height: 100%;
    top: 0;
    left: 0;
    cursor: url(../../../../public/assets/img/core-img/back.png), auto !important;
    z-index: 9;
}

.instargram_area .owl-next {
    position: absolute;
    width: 10%;
    background-color: transparent;
    height: 100%;
    top: 0;
    right: 0;
    cursor: url(../../../../public/assets/img/core-img/next.png), auto !important;
}


/* --------------------
:: 8.0 Footer Social Area CSS 
-------------------- */

.footer-social-area {
    border-bottom: 1px solid #eeeeee;
    padding: 50px 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.single-icon > a {
    color: #51545f;
    font-size: 14px;
    
    text-transform: uppercase;
}

.single-icon > a:hover {
    color: #fc6c3f;
}

.single-icon > a > i {
    font-size: 16px;
    padding-right: 12px;
}

.footer-content {
    padding: 50px 0 65px 0;
    border-bottom: 1px solid #eeeeee;
}

.footer-logo-area .notjamieoliver-logo {
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 60px;
    margin-bottom: 0;
    padding: 0 0 20px 0;
    display: inline-block;
    color: #232d37;
}

.copy_right_text {
    width: 100%;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.copy_right_text > p {
    font-size: 14px;
    color: #b5aec4;
    margin-bottom: 0;
}

.footer_area .navbar.navbar-expand-lg .navbar-nav .nav-link {
    padding-bottom: 0;
}

/* --------------------
:: 9.0 Goole Map Area CSS
-------------------- */

#googleMap {
    height: 620px;
    width: 100%;
}


/* --------------------
:: 10.0 Sidebar Area CSS
-------------------- */

/* About me */

.single-widget-area {
    margin-bottom: 50px;
}

.widget-title > h6 {
    background-color: var(--bs-primary-bg-subtle) !important;
    font-size: 20px;
    height: 40px;
    width: 100%;
    line-height: 40px;
    border-radius: 2px;
    margin-bottom: 30px;
    border-radius: 5px;
    font-weight: 500;
    
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    --bs-text-opacity: 1;
    text-align: center;
}

.about-me-widget-thumb img {
    height: 200px;
    width: 200px;
    border:#b5aec4;    ;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    margin: auto;
}

.about-me-widget-thumb img:hover {
    text-align: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border-style: solid;
    border-color: #0d6efd;
    border-width: 2px;
    margin: auto;
    display: block;
}

.about-me-widget h4 {
    color:white;
    font-weight: 300;
    margin: 15px 0;
}

.about-me-widget p {
    margin-bottom: 0;
}

/* subscribe_follow_area */

.subscribe-link > a {
    font-size: 14px;
    color: #ffffff;
    padding: 0 15px;
}

.subscribe-link > a:hover {
    color: #fc6c3f;
}

.subscribe-link {
    padding-top: 20px;
}

/* popular post */
.single-popular-post {
    display: flex;
    align-items: center;
}

.single-popular-post img,
.single-popular-post img a {
    border-radius: 5px;
    border-style: solid;
    border-color: #b5aec4;
    border-width: 2px;
}

.single-popular-post img:hover {
    text-align: center;
    width: 96%;
    border-radius: 5px;
    border-style: solid;
    border-color: #0d6efd;
    border-width: 2px;
    margin: auto;
    display: block;
}

.single-popular-post .post-image, 
.single-popular-post .post-content,
.single-popular-post .post-content a {
    flex: 1;
    color: #ffffff;
    font-size: 14px;
    
    
}

 
.single-popular-post .post-content:hover,
.single-popular-post .post-content a:hover {
    flex: 1;
    color: #0d6efd;
    font-size: 14px;
    
    
}

.single-popular-post .post-image:hover {
    flex: 1;
    color: #ffffff;
    font-size: 14px;
    
    
}


.popular-post-widget .single-popular-post img {
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 30px;
    -ms-flex-item-align: center;
    align-self: center;
    flex: 1;
}

.popular-post-widget .single-populer-post:last-of-type img {
    margin-bottom: 0;
}

.popular-post-widget .single-populer-post img {
    width: calc(50% - 10px);
}

.popular-post-widget .single-populer-post img,
.popular-post-widget .single-populer-post .post-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

.popular-post-widget .single-populer-post .post-content {
    margin-left: 10px;
}

.popular-post-widget .single-populer-post .post-content > p {
    font-size: 14px;
    color: #b5aec4;
    
    margin-bottom: 0;
}

/* Add Widget */

.add-widget .add-widget-area {
    position: relative;
    z-index: 1;
}

.add-widget .add-widget-area img {
    border-radius: 5px;
}

.add-widget .add-widget-area:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    background-color: rgba(30, 29, 29, 0.8);
    z-index: 1;
    border-radius: 5px
}

.add-widget .add-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}

.add-widget .add-text h2,
.add-widget .add-text p {
    color: #fff;
}

.add-widget .add-text p {
    font-size: 14px;
}

.add-widget .add-text a {
    min-width: 130px;
    height: 40px;
    border: 1px solid #fc6c3f;
    color: #fc6c3f;
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    line-height: 40px;
}

.add-widget .add-text a:hover {
    border: 1px solid #fc6c3f;
    color: #fff;
    background-color: #fc6c3f;
}

/* Newsletter Widget CSS */

.newsletter-widget p {
    font-size: 14px;
}

.newsletter-form {
    position: relative;
    z-index: 1;
}

.newsletter-form input {
    width: 100%;
    height: 40px;
    background-color: #f5f5f8;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 14px;
    color: #b5aec4;
}

.newsletter-form button {
    width: 40px;
    height: 40px;
    background-color: #fc6c3f;
    border: none;
    color: #fff;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
}

/* --------------------
:: 11.0 Breadcumb Area CSS
-------------------- */

.breadcumb-area {
    width: 100%;
    height: auto;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.breadcumb-area:after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
}

.breadcumb-area .bradcumb-title h2 {
    font-size: 48px;
    color: #fff;
}

.breadcumb-nav .breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: .25rem;
    margin-top: 30px;
}

.breadcumb-nav .breadcrumb .breadcrumb-item a {
    color: #232d37;
    font-size: 16px;
}

.breadcrumb-item + .breadcrumb-item:before {
    content: "\f105";
    font-family: 'FontAwesome'
}

/* --------------------
:: 12.0 Paginatio Area CSS
-------------------- */

.pagination-area {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

.pagination-area .pagination .page-link {
    color: #51545f;
    margin: 0 2.5px;
}

.pagination-area .pagination .page-item.active .page-link,
.pagination-area .pagination .page-link:focus,
.pagination-area .pagination .page-link:hover {
    color: #fff;
    background-color: #fc6c3f;
    border-color: transparent;
}

.pagination-area .page-status p,
.contact-info-area .single-contact-info p {
    margin-bottom: 0;
}

/* --------------------
:: 13.0 Contact Area CSS
-------------------- */

.contact-form .form-control,
.comment-form .form-control {
    border: 1px solid #ebebeb;
    height: 50px;
}

.contact-form .form-control:focus,
.comment-form .form-control:focus {
    border: 1px solid #000;
}

.contact-form textarea.form-control,
.comment-form textarea.form-control {
    height: 130px;
}

.contact-btn {
    margin-top: 50px;
    width: 160px;
    height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    
    color: #fff;
    background-color: #fc6c3f;
    border-radius: 5px;
}

.contact-btn:hover {
    color: #fff;
    background-color: #d43f10;
}

.contact-form-sidebar {
    background-size: cover;
    background-position: center center;
}

/* -------------------- 
:: 14.0 Single Blog CSS
-------------------- */

.single-post-share-info a {
    width: 30px;
    display: block;
    height: 30px;
    background-color: #ddd;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-bottom: 20px;
}

.single-post-share-info a i {
    line-height: 30px;
}

.single-post-share-info a.facebook {
    background-color: #4c65a8;
}


.single-post-share-info a.facebook:hover {
    background-color: #3555ac;
}

.single-post-share-info a.twitter {
    background-color: #41a1f6;
}

.single-post-share-info a.twitter:hover {
    background-color: #087ee6;
}

.single-post-share-info a.googleplus {
    background-color: #f43535;
}

.single-post-share-info a.googleplus:hover {
    background-color: #e20707;
}

.single-post-share-info a.instagram {
    background-color: #8f6247;
}

.single-post-share-info a.instagram:hover {
    background-color: #6d4025;
}

.single-post-share-info a.pinterest {
    background-color: #f0c605;
}

.single-post-share-info a.pinterest:hover {
    background-color: #a88c0a;
}

.notjamieoliver-blockquote {
    padding-left: 30px;
    border-left: 2px solid #fc6c3f;
}

.notjamieoliver-blockquote h5 {
    font-size: 18px;
}

.notjamieoliver-blockquote h6 {
    font-size: 14px;
    text-transform: uppercase;
}

.br-30 {
    border-radius: 5px !important;
}

.single_blog_area ul li {
    position: relative;
    z-index: 1;
    color: #51545f;
    margin-bottom: 15px;
    padding-left: 30px;
}

.single_blog_area ul li:before {
    position: absolute;
    content: '\f111';
    left: 0;
    top: 5px;
    font-family: 'FontAwesome';
    font-size: 8px;
    color: #fc6c3f;
}

.tags-area {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 30px;
}

.tags-area a {
    color: #292f3b;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 12px;
    background-color: #ebebeb;
    display: inline-block;
    margin-bottom: 3px;
}

.tags-area a:hover {
    color: #fff;
    background-color: #fc6c3f;
}

.related-post-area {
    border-bottom: 1px solid #ebebeb;
}

.related-post-area .single-post {
    margin-bottom: 0;
}

.related-post-slider .post-content h6 {
    line-height: 1.5;
    margin-bottom: 0;
}

.related-post-slider .post-thumb img {
    margin-bottom: 10px;
}

.related-post-slider .owl-prev,
.related-post-slider .owl-next {
    position: absolute;
    top: -60px;
    border: 1px solid #ebebeb;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    right: 30px;
    background-color: #fff;
}

.related-post-slider .owl-next {
    right: 0;
}

.comment_area {
    border-bottom: 1px solid #ebebeb;
}

.single_comment_area {
    position: relative;
    z-index: 1;
}

.single_comment_area:after {
    position: absolute;
    width: 1px;
    height: 90%;
    background-color: #ebebeb;
    left: 100px;
    top: 0;
    z-index: 2;
    content: ''
}

.single_comment_area ol li.single_comment_area {
    margin-left: 130px;
}


.single_comment_area ol li.single_comment_area:after {
    display: none;
}


.comment-wrapper {
    margin-bottom: 30px;
}

.comment-wrapper .comment-author {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 60px;
}

.single_comment_area ol li.single_comment_area .comment-wrapper .comment-author {
    margin-right: 30px;
}

.comment-wrapper .comment-author img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.comment-wrapper .comment-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.comment-wrapper .comment-date {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.comment-wrapper .comment-content h5 {
    font-size: 18px;
    margin: 5px 0;
}

.comment-wrapper .comment-content a {
    min-width: 80px;
    height: 30px;
    border: 1px solid #ebebeb;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    color: #232d37;
    display: inline-block;
}

.comment-wrapper .comment-content a.active {
    color: #fff;
    background-color: #fc6c3f;
}

/* Bg Pattern */
.bg-pattern {
    background-attachment: fixed;
    background-position: center center;
    background-image: url(../../../../public/assets/img/bg-img/bg-pattern.jpg);
    position: relative;
    background-size: cover;
    z-index: 1;
}
.bg-pattern:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    background-color: rgba(255, 255, 255, 0.95);
    z-index: -1;
}

#pattern-switcher {
	width: 120px;
	background-color: #fc6c3f;
	position: fixed;
	top: 15%;
	z-index: 9999;
	cursor: pointer;
	height: 50px;
    right: 0;
    color: #fff;
	font-size: 12px;
	line-height: 50px;
	text-align: center;
	text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
}

#pattern-switcher.pattern-remove {
    right: -200px;
}

#pattern-switcher:hover {
	background-color: #e3410e;
}

#patter-close > i {
	position: fixed;
	width: 20px;
	height: 20px;
	background-color: #111;
	font-size: 11px;
	line-height: 20px;
	border-radius: 50%;
	right: 110px;
	top: calc(15% - 10px);
	text-align: center;
	z-index: 99999;
	color: #fff;
	cursor: pointer;
}